import {createRouter, createWebHashHistory, RouteLocationNormalizedLoaded} from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { NO_RESET_WHITE_LIST } from '@/constants'

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    name: 'Root',
    meta: {
      hidden: true
    },
	  children: [
		  {
			  path: 'home',
			  component: () => import('@/views/home/index.vue'),
			  name: 'home',
			  meta: {
				  hidden: true,
				  title: '首页',
				  // affix: true
			  }
		  }
	  ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: '登录',
      noTagsView: true
    }
  },{
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  },
	{
		path: '/redirect',
		component: Layout,
		name: 'Redirect',
		children: [
			{
				path: '/redirect/:path(.*)',
				name: 'Redirect',
				component: () => import('@/views/Redirect/Redirect.vue'),
				meta: {}
			}
		],
		meta: {
			hidden: true,
			noTagsView: true
		}
	}
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
	{
	  path: '/meituan',
	  name: 'Meituan',
	  component: Layout,
	  redirect: '/meituan/product',
	  meta: {
	    icon: 'vi-tdesign:shop',
	    title: "美团管理",
		affix: false
	  },
	  children: [
	    {
	      path: 'product',
	      name: 'product',
	      component: () => import('@/views/meituan/index.vue'),  
	      meta: {
	        // affix: true,
	        title: "商品管理",
	      },
	    },
		  {
			  path: 'orderlist',
			  name: 'orderlist',
			  component: () => import('@/views/meituan/order/index.vue'),
			  meta: {
				  // affix: true,
				  title: "订单管理",
			  },
		  },
		  {
			  path: 'xianxiaoproduct',
			  name: 'xianxiaoproduct',
			  component: () => import('@/views/meituan/xianxiaolist/index.vue'),
			  meta: {
				  // affix: true,
				  title: "限销商品",
			  },
		  },
		  {
			  path: 'updownlist',
			  name: 'updownlist',
			  component: () => import('@/views/meituan/updown/index.vue'),
			  meta: {
				  // affix: true,
				  title: "定时上下架",
			  },
		  },
		  {
			  path: 'changepricelist',
			  name: 'changepricelist',
			  component: () => import('@/views/meituan/changeprice/index.vue'),
			  meta: {
				  // affix: true,
				  title: "定时改价",
			  },
		  },
		  {
			  path: 'oprarecordlist',
			  name: 'oprarecordlist',
			  component: () => import('@/views/meituan/oprarecord/index.vue'),
			  meta: {
				  // affix: true,
				  title: "操作记录",
			  },
		  },
		  {
			  path: 'oprarecorddetail',
			  name: 'oprarecorddetail',
			  component: () => import('@/views/meituan/oprarecord/detail.vue'),
			  meta: {
				  hidden: true,
				  title: "操作记录详情",
				  canTo: true,
				  activeMenu: '/meituan/product',
			  },
		  },
		  {
			  path: 'salestatistics',
			  name: 'salestatistics',
			  component: () => import('@/views/meituan/salestatistics/index.vue'),
			  meta: {
				  // affix: true,
				  title: "销售排行",
			  },
		  },
	  ],
	},
	{
		path: '/caiwu',
		name: 'Caiwu',
		component: Layout,
		redirect: '/caiwu/orderfree',
		meta: {
			icon: 'vi-ant-design:account-book-outlined',
			title: "财务管理",
			alwaysShow: true
		},
		children: [
			{
				path: 'orderfree',
				name: 'orderfree',
				component: () => import('@/views/caiwu/orderfree/index.vue'),
				meta: {
					// affix: true,
					title: "订单费用",
				},
			},
		]
	}
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 }),
	// scrollBehavior(to: RouteLocationNormalizedLoaded, from: RouteLocationNormalizedLoaded, savedPosition) {
	// 	// 如果有保存的位置，滚动到该位置
	// 	if (savedPosition) {
	// 		return savedPosition;
	// 	}
	// 	// 如果目标路由有 hash，滚动到该 hash 对应的位置
	// 	if (to.hash) {
	// 		return {
	// 			el: to.hash,
	// 			behavior: 'smooth',
	// 		};
	// 	}
	// 	// 否则滚动到顶部
	// 	return { top: 0 };
	// },
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
